<nz-card class="stats_plans_card">
  <div nz-row nzJustify="start">
    <div nz-col nzSpan="24" class="p_card_title">
      <span class="p_card_value" *ngIf="type === 'value'">{{
        displayValue
      }}</span>
      {{ title }}
    </div>
    <div nz-col nzSpan="24" class="p_card_subtitle">
      {{ subTitle }}
      <span class="p_card_unit" *ngIf="type === 'value'">{{ unit }}</span>
    </div>
  </div>

  @if (type === 'button') {
  <ui-data-download
    [styles]="{ 'margin-top': '5px' }"
    [menuOptions]="menuOptions"
    (selectOptionEvent)="selectOptionEvent.emit($event)"
  />
  }
</nz-card>
