import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UI_NGZORRO } from '../ui-ngzorro';

@Component({
    selector: 'ui-stats-sales',
    templateUrl: './ui-stats-sales.component.html',
    styleUrl: './ui-stats-sales.component.less',
    standalone: true,
    imports: [UI_NGZORRO],
    changeDetection:ChangeDetectionStrategy.OnPush
})
export class UiStatsSalesComponent {
  @Input({ required:true })
  public title!:string;

  @Input({required:true})
  public displayValue!:string

  @Input()
  public unit?:string | number;
}
