import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UI_NGZORRO } from '../ui-ngzorro';
import { UiDataDownloadComponent } from '../ui-data-download/ui-data-download.component';
import { CommonModule } from '@angular/common';
import { MenuDropdownModel } from '../../models';

function transformUnitFn(unit: string | number): string | number {
  if (unit && typeof unit === 'string') {
    return unit[0].toUpperCase() + unit.substring(1);
  }

  return unit;
}

@Component({
  selector: 'ui-stats-plans',
  templateUrl: './ui-stats-plans.component.html',
  styleUrl: './ui-stats-plans.component.less',
  standalone: true,
  imports: [UI_NGZORRO, UiDataDownloadComponent, CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiStatsPlansComponent {
  @Input({ required: true })
  public type!: 'value' | 'button';

  @Input({ required: true })
  public title!: string;

  @Input()
  public subTitle!: string;

  @Input()
  public displayValue?: string | number;

  @Input({ transform: transformUnitFn })
  public unit?: string | number;

  @Input()
  public height = '233px';

  @Input()
  public menuOptions: MenuDropdownModel[] = [
    {
      key: 'PDF',
      label: 'PDF',
    },
    {
      key: 'XLSX',
      label: 'EXCEL',
    },
  ];

  @Output()
  public selectOptionEvent = new EventEmitter<string>();
}
