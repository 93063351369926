/* eslint-disable @typescript-eslint/no-explicit-any */
import { DropdownModel, MenuDropdownModel } from "../interfaces/ui.interfaces";

export class Dropdown implements DropdownModel {
  public buttonLabel: string;
  public menuTitle: string;
  public activeLabel?: string | undefined;
  public menu: MenuDropdownModel[]; 
  public selected: string | null;
  public command:(key: any) => void;

  constructor(
    configs: DropdownModel,
    command:(key: any) => void
  ) {
    this.buttonLabel = configs.buttonLabel;
    this.menuTitle = configs.menuTitle;
    this.activeLabel = configs.activeLabel;
    this.menu = configs.menu;
    this.command = command;
    this.selected = configs.selected??null;
  }

  public onSelected(key:string){
    this.selected = key;
  }

  public onChangeMenu(newMenu:MenuDropdownModel[]){
    this.menu = newMenu;
  }
}
